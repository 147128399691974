// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from '@modules/react';
import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';

import AboutLinkHOC from './about-link/about-link';
import consts from './consts';

const PLUGIN_NAME = 'About';

const AboutPlugin: ComponentBuilder = ({ actionCreators, store }) => {
    const TermsOfUse = React.memo(AboutLinkHOC('Terms of use', consts.TERMS_OF_USE));
    const PrivacyPolicy = React.memo(AboutLinkHOC('Privacy policy', consts.PRIVACY_POLICY));

    store.dispatch(actionCreators.addUIComponent('about.links.items', TermsOfUse, { weight: 40 }));
    store.dispatch(actionCreators.addUIComponent('about.links.items', PrivacyPolicy, { weight: 50 }));

    return {
        name: PLUGIN_NAME,
        destructor: () => {
            store.dispatch(actionCreators.removeUIComponent('about.links.items', TermsOfUse));
            store.dispatch(actionCreators.removeUIComponent('about.links.items', PrivacyPolicy));
        },
    };
};

function register() {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
            .cvatUI.registerComponent(AboutPlugin);
    }
}

window.addEventListener('plugins.ready', register, { once: true });
